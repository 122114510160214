<template>
  <validation-observer ref="simpleRules">
    <b-card title="Başvuru Durumu Güncelle">
      <v-select
        id="category"
        v-model="newStartup.id_startup_statuses"
        label="title"
        :options="startupStatuses"
        :reduce="item => item.id"
        placeholder="Seçiniz"
      />
      <b-row class="mt-1">
        <b-col
          v-for="(item,key) in startupReports"
          :key="key"
          cols="12"
          md="4"
        >
          <b-form-checkbox
            v-model="item.status"
            value="1"
            switch
          >
            {{ item.title }}
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-button
        variant="primary"
        class="mt-2"
        @click="submitForm"
      >
        Kaydet
      </b-button>
    </b-card>
    <hr>
    <b-row>
      <b-col
        xs="12"
        md="3"
      >
        <startup-summary />
      </b-col>
      <b-col>
        <startup-details />
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import {
  BCard, BButton, BRow, BCol, BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationObserver, localize } from 'vee-validate'
import vSelect from 'vue-select'
import { required } from '@validations'
import StartupSummary from '@/views/Dealers/Startup_applies/PreApplicationView/Summary.vue'
import StartupDetails from '@/views/Dealers/Startup_applies/PreApplicationView/Details.vue'

export default {
  name: 'PreApplicationView',
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BFormCheckbox,
    StartupSummary,
    StartupDetails,
    ValidationObserver,
    vSelect,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    startupReports() {
      return this.$store.getters['dealerPreApplicationViewReport/startupReports']
    },
    newStartup() {
      return this.$store.getters['dealerPreApplicationViewReport/getPreApplicationReport']
    },
    startupStatuses() {
      return this.$store.getters['startupStatuses/getStartupStatuses']
    },
    saveStatus() {
      return this.$store.getters['dealerPreApplicationViewReport/getPreApplicationReportSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: 'Kayıt işleminiz başarılı!',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: 'Kayıt işleminiz yapılamadı.! Lütfen tekrar deneyiniz.',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
    },
  },
  created() {
    localize(this.$i18n.locale)
    this.getData()
    this.getStartupStatuses()
  },
  methods: {
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('dealerPreApplicationViewReport/preApplicationReportSave', {
            id_startup_statuses: this.newStartup.id_startup_statuses,
            id_startups: this.newStartup.id,
            startupReports: this.startupReports,
          })
        }
      })
    },
    getData() {
      this.$store.dispatch('dealerPreApplicationViewReport/preApplicationReportView', this.$route.params.id)
    },
    getStartupStatuses() {
      this.$store.dispatch('startupStatuses/startupStatusesList')
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
