<template>
  <b-card>
    <b-list-group>
      <list-items
        :item-title="$t('Hedef Müşteriler')"
        :item-value="newStartup.target_customers"
      />
      <list-items
        :item-title="$t('İş Tanımı')"
        :item-value="newStartup.description"
      />
      <list-items
        :item-title="$t('İş Açıklaması')"
        :item-value="newStartup.content"
      />
      <list-items
        :item-title="$t('Problemler')"
        :item-value="newStartup.problems"
      />
      <list-items
        :item-title="$t('Çözümler')"
        :item-value="newStartup.solutions"
      />
      <list-items
        :item-title="$t('Gelir Modeli')"
        :item-value="newStartup.revenue_model"
      />
      <list-items
        :item-title="$t('Rekabet Avantajları')"
        :item-value="newStartup.competitive_advantages"
      />
      <list-items
        :item-title="$t('Pazar')"
        :item-value="newStartup.market"
      />
      <list-items
        :item-title="$t('Alınan Ödül, Yatırım, Teşvik ve Destekler')"
        :item-value="newStartup.award_supports"
      />
      <list-items
        :item-title="$t('Talep Edilen Yatırım Miktarı')"
        :item-value="newStartup.demand_investment | toCurrency"
        item-addon="₺"
      />
      <list-items
        :item-title="$t('Ar-Ge (R&D) (%)')"
        :item-sub-title="$t('(Talep edilen yatırım miktarı toplam %100 olarak şekilde, ilgili giderin yüzdesini yazınız.)')"
        :item-value="newStartup.rd_percent"
        item-prepend="%"
      />
      <list-items
        :item-title="$t('Pazarlama & Satış & Dağıtım(S & M)( %)')"
        :item-sub-title="$t('(Talep edilen yatırım miktarı toplam %100 olarak şekilde, ilgili giderin yüzdesini yazınız.)')"
        :item-value="newStartup.sm_percent"
        item-prepend="%"
      />
      <list-items
        :item-title="$t('Genel Yönetim (G&A) (%)')"
        :item-sub-title="$t('(Talep edilen yatırım miktarı toplam %100 olarak şekilde, ilgili giderin yüzdesini yazınız.)')"
        :item-value="newStartup.ga_percent"
        item-prepend="%"
      />
      <list-items
        :item-title="$t('Satılan Mal Maliyeti (COGS) (%)')"
        :item-sub-title="$t('(Talep edilen yatırım miktarı toplam %100 olarak şekilde, ilgili giderin yüzdesini yazınız.)')"
        :item-value="newStartup.cogs_percent"
        item-prepend="%"
      />
      <list-items
        :item-title="$t('OPEX Operasyon Giderleri (%)')"
        :item-sub-title="$t('(Talep edilen yatırım miktarı toplam %100 olarak şekilde, ilgili giderin yüzdesini yazınız.)')"
        :item-value="newStartup.opex_percent"
        item-prepend="%"
      />
      <list-items
        :item-title="$t('CAPEX Sermaye Giderleri (%)')"
        :item-sub-title="$t('(Talep edilen yatırım miktarı toplam %100 olarak şekilde, ilgili giderin yüzdesini yazınız.)')"
        :item-value="newStartup.capex_percent"
        item-prepend="%"
      />
      <list-items
        :item-title="$t('Diğer( %)')"
        :item-sub-title="$t('(Talep edilen yatırım miktarı toplam %100 olarak şekilde, ilgili giderin yüzdesini yazınız.)')"
        :item-value="newStartup.other_percent"
        item-prepend="%"
      />
      <team-items
        :item-title="$t('Ekip')"
        :item-data="newStartup.startup_teams"
      />
      <references-items
        :item-title="$t('Referanslar')"
        :item-data="newStartup.startup_references"
      />
    </b-list-group>
  </b-card>
</template>

<script>
import { BCard, BListGroup } from 'bootstrap-vue'
import ListItems from '@/views/Admin/Startup_applies/PreApplicationView/components/ListItems.vue'
import TeamItems from '@/views/Admin/Startup_applies/PreApplicationView/components/TeamItems.vue'
import ReferencesItems from '@/views/Admin/Startup_applies/PreApplicationView/components/ReferencesItems.vue'

export default {
  name: 'Details',
  components: {
    BCard,
    BListGroup,
    ListItems,
    TeamItems,
    ReferencesItems,
  },
  computed: {
    newStartup() {
      return this.$store.getters['dealerPreApplicationViewReport/getPreApplicationReport']
    },
  },
}
</script>

<style scoped>

</style>
